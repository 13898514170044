.l-projects {
	overflow: hidden;
	margin: 0 auto;
	width: 80%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: column;

	&--single {
		width: 100%;
		margin-bottom: $gutter-sm;

		&:last-child {
			margin-bottom: 0;
		}

		position: relative;

		.tile,
		.video-player {}
	}
	&--title {
		@include base-heading;
		text-align: center;
		margin: 0;
		margin-bottom: $gutter-sm;
		line-height: 1;

		font-size: 20px;
		letter-spacing: 0.18em;
		font-weight: 800;
	}

	&__main {
		margin: 20vh auto;
	}
}

@include from($bp-medium) {
	.l-projects {
		width: 66.666%;
		&--single {
			width: 100%;
			margin-bottom: $gutter;

			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				content: '';
				display: block;
				padding-bottom: 50%;
			}

			.tile,
			.video-player {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				right: 0;
			}

		}
		&--title {
			font-size: 3.3vw;
		}
	}
}

.l-showreel {
	width: 80%;
	margin: 20vh auto;
}

@include from($bp-medium) {
	.l-showreel {
		width: 66.666%;
	}
}