.video-player {
	position: relative;
	&--cover,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&--cover {
		display: block;
		text-decoration: none;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 0;
			height: 0;
			transform: translate(-50%, -50%);
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 20px solid #E6E6E6;
		}
	}
	&--thumbnail {
		width: 100%;
		height: auto;
		display: block;
	}

	&__alt {
		.video-player {
			&--cover {
				background: rgba(black, 0.9);

				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				&:before {
					position: static;
					transform: none;
				}
				&-label {
					@include base-heading;
					margin-top: 12px;
					font-size: $fs-small;
				}
			}
		}
	}
}


@include from($bp-medium) {
	.video-player {
		&--cover {
			&:before {
				border-top: 30px solid transparent;
				border-bottom: 30px solid transparent;
				border-left: 50px solid #E6E6E6;
			}
		}
		&__alt {
			.video-player {
				&--cover {
					transition: opacity 300ms ease-in-out;
					opacity: 0;
					&-label {
						font-size: $fs-large;
					}
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
}