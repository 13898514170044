.block-richtext .rich-text,
.block-video,
.block-dual_image,
.block-image {
	padding: 0 10%;
}

.block-richtext {
	margin-bottom: 11vh;
}

.block-dual_image {
	img {
		width: 100%;
		height: auto;
		display: block;
		margin-bottom: $gutter-sm;
	}
}

.block-image {
	img {
		width: 100%;
		height: auto;
		display: block;
	}
}

// Space them all out
.block-image + .block-richtext,
.block-video + .block-richtext,
.block-dual_image + .block-richtext {
	margin-top: 11vh;
}

.block-dual_image,
.block-image,
.block-video {
	+ .block-dual_image,
	+ .block-image,
	+ .block-video {
		margin-top: $gutter-sm;
	}
}


@include from($bp-medium) {
	.block-richtext {
		margin-bottom: 11vh;
	}

	.block-richtext .rich-text {
		padding: 0 24%;
	}

	.block-video {
		padding: 0 $gutter;
	}

	.block-dual_image {
		display: flex;
		padding: 0 $gutter;
		margin-bottom: $gutter;
		justify-content: space-between;
		align-items: flex-start;
		img {
			width: calc(50% - 34px);
			height: auto;
			display: block;
			margin-bottom: 0;
		}
	}

	.block-image {
		padding: 0 $gutter;
	}

	// Space them all out
	.block-image + .block-richtext,
	.block-video + .block-richtext,
	.block-dual_image + .block-richtext {
		margin-top: 11vh;
	}

	.block-dual_image,
	.block-image,
	.block-video {
		+ .block-dual_image,
		+ .block-image,
		+ .block-video {
			margin-top: $gutter;
		}
	}
}
