.l-home-splash {
	width: 100%;
	height: 80vh;
	position: relative;
	&--image {
		position: absolute;
		background-position: center center;
		background-size: cover;
		overflow: hidden;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	&--video {
		display: none;
	}
	&--logo {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 60%;
		z-index: 2;
	}
}

@include from($bp-medium) {
	.l-home-splash {
		&--logo {
			width: 33.333%;
		}
		&--image {
			display: none;
		}
		&--video {
			display:inline;
			position: absolute;
			overflow: hidden;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			&-file {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
			}
		}
	}
}
