.tile {
	position: relative;
	width: 100%;
	height: 100%;
	color: white;
	font-family: Helvetica;
	overflow: hidden;
	flex-direction: column-reverse;
	display: flex;
	text-decoration: none;

	&--cover {
		transition: opacity 300ms ease-in-out;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		h2 {
			text-align: center;
			font-size: $fs-small;
		}
	}
	&--video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 1;
	}
	&:hover {
		.tile--cover {
			opacity: 1;
		}
	}
}

@include from($bp-medium) {
	.tile {
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		font-family: Helvetica;
		overflow: hidden;

		&--cover {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 34px;
			opacity: 0;
			background: rgba(black, 0.9);
			h2 {
				font-size: $fs-large;
			}
		}
		&--video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 1;
		}
		&:hover {
			.tile--cover {
				opacity: 1;
			}
		}
	}
}