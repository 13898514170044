@mixin base-heading() {
	font-family: "aktiv-grotesk", sans-serif;
	text-transform: uppercase;
	color: #e6e6e6;
	font-weight: 400;
	letter-spacing: 4px;
}

h1,
h2,
h3,
h4 {
	@include base-heading;
}

.rich-text {
	overflow: hidden;
	> * {
		margin: 0;
		margin-bottom: 1em;
		font-weight: 100;
		font-size: 14px;
		letter-spacing: 1px;
		line-height: 1.6;
	}

	a {
		position: relative;
		text-decoration: none;
		color: #e6e6e6;
		&:after {
			transition: opacity 200ms ease-in-out;
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background: #e6e6e6;
			left: 0;
			bottom: 0;
			opacity: 0.4;
		}
		&:hover {
			&:after {
				opacity: 0.9;
			}
		}
	}

	h1,
	h2,
	h3,
	h4 {
		font-size: 24px;
		margin-top: 3em;
		text-align: center;
	}

	img {
		&.right {
			float: right;
		}
		&.full-width {
			display: block;
			margin: 0 auto;
		}
	}
}

@include from($bp-medium) {
	.rich-text {
		> * {
			font-size: 18px;
		}
	}
}
