.l-site-footer {
	text-transform: uppercase;
	color: #E6E6E6;
	text-align: center;
	letter-spacing: 4px;
	margin-top: 124px;
	margin-bottom: $gutter;
	&--navigation {
		margin: 0 auto;
		text-align: center;
		margin-bottom: $gutter;
		font-size: $fs-small;
		&-item {
			transition: color 200ms ease-in-out;
			text-decoration: none;
			margin: 0 $gutter / 2;
			@include base-heading;
			&:hover {
				color: #6C6C6C;
			}
		}
	}
	&--contact {
		margin: 0;
		padding: 0 4%;
		font-size: 10px;
		color: #6C6C6C;

		&__social {
				padding-top: 25px;
				font-size: 1.4em;
				letter-spacing: 10px;
				a {
					transition: color 200ms ease-in-out;
					&:after {
						display:none;
					}
					&:hover {
						color: #DCDDDE;
					}
				}
			}
		& a {
			position: relative;
			text-decoration: none;
			color: #6C6C6C;
			&:after {
				transition: opacity 200ms ease-in-out;
				content: '';
				position: absolute;
				width: 100%;
				height: 0.5px;
				background: #6C6C6C;
				left: 0;
				bottom: 0;
				opacity: 0.4;
			}
			&:hover {
				&:after {
					opacity: 0.9;
				}
			}
		}
	}

	&--nj-mark {
		margin-top: 60px;
		padding: 0 4%;
		width: 92%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.8em;
		color: #6C6C6C;
		text-decoration: none;

		& > img {
			width: 40px;
			height: 40px;
			margin-left: 10px;
		}
	}
}

@include from($bp-medium) {
	.l-site-footer {
		&--navigation {
			font-size: $fs-large;
		}
	}
}
