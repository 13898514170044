.l-project-page {
	&--header {
		position: relative;
		img,
		picture {
			width: 100%;
			height: 80vh;
			object-fit: cover;
			display: block;
		}
		.video-player {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	&--title {
		padding: 0 16.666%;
		margin: 0;
		height: 20vh;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		text-align: center;
	}

	&--footer {
		margin: 11vh 0;
		&-logo {
			margin: 0 auto;
			width: 60%;
			display: block;
			&-img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
}

@include from($bp-medium) {
	.l-project-page {
		&--footer {
			&-logo {
				width: 300px;
			}
		}
	}
}
